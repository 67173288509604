import React from 'react';
import "./Features.css";

import SingleFeatures from './SingleFeatures';
import { useTranslation } from 'react-i18next';

const Features = () => {
    const {t} = useTranslation()
    const features = [
        {
            id: 10,
            title: t("features.item1.title"),
            text: t("features.item1.text")
        },
        {
            id: 11,
            title: t("features.item2.title"),
            text: t("features.item2.text")
        },
        {
            id: 12,
            title: t("features.item3.title"),
            text: t("features.item3.text")
            
        },
        {
            id: 13,
            title: t("features.item4.title"),
            text: t("features.item4.text")
        }, 
        {
            id: 14,
            title: t("features.item5.title"),
            text: t("features.item5.text")
        },
        
    ];
  return (
    <section className='features section-p bg-black' id = "features">
      
        <div className='container'>
            <div className='features-content'>
            <div className="about-content text-center">
        <h5 className="aboutUs-heading">{t("features.heading")}</h5>

        <p className="text text-center leadingText">
          {t("features.leadText")}
        </p>
       <br/>
      </div>
                <div className='item-list text-white'>
                    {
                        features.map(feature => {
                            return (
                                <SingleFeatures key = {feature.id} {...feature} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default Features
