import React from 'react';
import "./Contact.css";

const Map = () => {
  return (
    <div className='map-container'>
    <div className='map-content'>
        {/* eslint-disable-next-line */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3249.0620616629703!2d44.41951367577866!3d35.47800827265344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDI4JzQwLjgiTiA0NMKwMjUnMTkuNSJF!5e0!3m2!1sen!2sin!4v1709032675665!5m2!1sen!2sin" width="100%" height="450" style={{border:0}}  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <div className='mapbutton'>
    <a href='https://maps.app.goo.gl/3szbC1U9pSijdnYG9'  id="contactBtn" class="btn mapButton  btn-blue">
      <span>Open In google Map</span>
     </a>
    </div>
    </div>
    </div>

  )
}

export default Map
