import React from "react";
import "./About.css";
import aboutSecondImage from "../../assets/images/abouts us second section left.gif";
import { useTranslation } from "react-i18next";
import useTextDirection from "../../languages/languagehook/usetextDirection";


const About = () => {
  const { t } = useTranslation();
  const direction = useTextDirection();
 
  return (
    <section className="about section-p bg-black" id="about">
      <div className="about-content text-center" dir={direction}>
        
        <h5 className="aboutUs-heading">{t("aboutus.heading")}</h5>

        <p className="text text-center leadingText">
          {t("aboutus.leadingText1")}
      
        </p>
        <p className="text text-center leadingText">
         {t("aboutus.leadingText2")}
         
        </p>
      </div>
     
     
        <div className="second-section">
          <div className="second-section-left " dir={direction}>
            <div>
            <span className="second-section-badgetext">
              {t("aboutus.miniLeadingText2")}
            </span>
            <h1 className="second-section-left-text">
              {t("aboutus.subheading")}
            </h1>
            <p className=" text second-section-leading-text">
             {t("aboutus.paragraph")}
            </p>
            </div>
       
          </div>
          <div className="second-section-right">
            <img src={aboutSecondImage} height={400} alt="about img" />
          </div>
        </div>
       
      
    </section>
  );
};

export default About;
