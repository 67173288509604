import React from 'react'


function ProjectCard({srcFile, url}) {
  const handlescroll = (itemName) => {
   
    const element = document.getElementById(itemName);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleClick = () => {
    if (url) {
      window.open(url, '_blank'); 
    } else {
      handlescroll("contact")
    }
  };
  
  return (
    <div  > <img src={srcFile} height="100%" width={20} alt="slide_image" /> 
    <div onClick={()=>{handleClick(url)}} className="btn card-btn btn-blue">
        <span>Explore</span>
    </div>
    </div>
  )
}

export default ProjectCard