

import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const [selectValue, setSelectValue] = React.useState(i18n.language);
  const onChange = (event) => {
    const value = event.target.value;
    setSelectValue(value);
    changeLanguage(value)
  };

  return (
    <div >
    <span className="mb-3"> Select Language</span>
    <select value={selectValue} onChange={onChange} className="form-select">
      
       

      <option value="ar">Arabic</option>
      <option value="en">English</option>
   
    </select>
    
  </div>
 
  );
}

export default LanguageSelector;
