import React from 'react'
import "./Header.css";
import { useTranslation } from 'react-i18next';

function ContactUsBtn() {
  const {t} = useTranslation()
    const handleItemClick = (itemName) => {
   
        const element = document.getElementById(itemName);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <a onClick={()=>{handleItemClick("contact")}}  className="btn header-btn btn-blue">
    <span>{t("navItems.contact")}</span>
   </a>
  )
}

export default ContactUsBtn