// useTextDirection.js

import { useTranslation } from 'react-i18next';

function useTextDirection() {
  const { i18n } = useTranslation();

  return i18n.language === 'ar' ? 'rtl' : 'ltr';
}

export default useTextDirection;
