import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./slider.css";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

import project_baner_1 from "../../assets/images/Project Banner 1.png";
import project_baner_2 from "../../assets/images/Project Banner 2.png";
import project_baner_3 from "../../assets/images/Project Banner 3.png";
import project_baner_4 from "../../assets/images/Project Banner 4.png";
import project_baner_5 from "../../assets/images/Project Banner 5.png";
import project_baner_6 from "../../assets/images/Project Banner 6.png";
import ProjectCard from "./ProjectCard";
import { useTranslation } from "react-i18next";

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const {t} = useTranslation()
  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };
  const cards = [
 
    {
      imageUrl: project_baner_1,
      title: "indiangrillhouse",
      url: "https://order.indiangrillhouse.se",
    },
  
    {
      imageUrl: project_baner_2,
      title: "CMS",
      url: "",
    },
    {
      imageUrl: project_baner_3,
      title: "taxilink",
      url: "https://taxilink.se",
    },
    {
      imageUrl: project_baner_4,
      title: "virtualInspect",
      url: "https://virtualinspect.se",
    },
    {
      imageUrl: project_baner_5,
      title: "DoctorOnline",
      url: "",
    },
    {
      imageUrl: project_baner_6,
      title: "instaride",
      url: "",
    }
  ];
  const hangleClick = () => {};
  return (
    <div className="containers bg-black">
     <div className="about-content text-center">
        <h5 className="aboutUs-heading">{t("projects.heading")}</h5>

        <p className="text text-center leadingText">
      
        {t("projects.leadingText")}
        </p>
     
      </div>
      <Swiper
      autoplay
        effect={"coverflow"}
        grabCursor={true}
        onSlideChange={handleSlideChange}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        {cards.map((card, index) => (
          <SwiperSlide key={index} onClick={hangleClick}>
            <ProjectCard
              srcFile={card.imageUrl}
              index={index}
              currentIndex={currentIndex}
              url={card.url}
            />
          </SwiperSlide>
        ))}

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
}

export default Slider;
