// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import language files
import translationEN from './locales/en/translation.json';
import translationAR from './locales/ar/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: translationEN,
  },
  ar:{
    translation:translationAR

  }

};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  
  .init({
    resources,
    lng: 'ar', // default language
    fallbackLng: 'ar', // fallback language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
