import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import companyLogo from "../../assets/images/Companylogo.png"
import "./Navbar.css";
import { useTranslation } from 'react-i18next';

const Navbar = () => {

    const [navToggle, setNavToggle] = useState(false);
    const {t} = useTranslation()
    const navHandler = () => {
        setNavToggle(prevData => !prevData);
    }
    const handleItemClick = (itemName) => {
   
        const element = document.getElementById(itemName);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
      
  return (
    <nav className='navbar w-100 flex'>
        <div className='container w-100'>
            <div className='navbar-content flex fw-7'>
                <div className='brand-and-toggler flex flex-between w-100'>
                    <Link to = "/" className='navbar-brand .fs-18'><div className='logo'><img src={companyLogo}   alt='Logo'/></div></Link>
                    <div type = "button" className={`hamburger-menu ${navToggle ? 'hamburger-menu-change' : ""}`} onClick={navHandler}>
                        <div className='bar-top'></div>
                        <div className='bar-middle'></div>
                        <div className='bar-bottom'></div>
                    </div>
                </div>

                <div className={`navbar-collapse ${navToggle ? 'show-navbar-collapse' : ""}`}>
                    <div className='navbar-collapse-content'>
                        <ul className='navbar-nav'>
                           
                            <li className='text-white' onClick={()=>{handleItemClick("services")}}>
                                <Link to = "/">{t("navItems.services")}</Link>
                            </li>
                            <li className='text-white' onClick={()=>{handleItemClick("products")}}>
                                <Link to = "/">{t("navItems.projects")}</Link>
                            </li>
                            <li className='text-white' onClick={()=>{handleItemClick("contact")}}>
                                <Link to = "/">{t("navItems.contact")}</Link>
                            </li>
                        </ul>

                      
                    </div>
                </div>
            </div>
        </div>
    </nav>
  )
}

export default Navbar