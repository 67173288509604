import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Header.css";

const Header = () => {
  const handleItemClick = (itemName) => {
   
    const element = document.getElementById(itemName);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Navbar />

   
  );
};

export default Header;
