import React, { useEffect } from 'react'
import ContactUsBtn from '../Header/ContactUsBtn';
import { useTranslation } from 'react-i18next';


function HeroSection() {
    const {t}= useTranslation()
    useEffect(() => {
       
    
        var typed = new window.Typed('.typed-words', {
            strings: ["Web Apps"," Websites"," Mobile Apps" ],
            typeSpeed: 80,
            backSpeed: 80,
            backDelay: 4000,
            startDelay: 1000,
            loop: true,
            showCursor: true
          });
        // Cleanup function
        return () => {
          // Destroy Typed instance to prevent memory leaks
          typed.destroy();
        };
      }, []);
  return (
    <div className="site-blocks-cover">
    <div className="textcontainers">
      <div className="row ">

        <div className="col-md-12" >

         
            <div className="items-center text-center">
           
              <h1>{t("heroHeading.text")} <span className="typed-words"></span></h1>
              <br/>
              <ContactUsBtn/>
            </div>
       
         

        </div>
      </div>
    </div>
  </div> 
  )
}

export default HeroSection