import React from 'react';
import "./Contact.css";

import { Formik } from 'formik';
import Info from './Info';
import Map from './Map';
import { FaFacebook } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import { BiPaperPlane } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';


const Contact = () => {
    const {t} = useTranslation()
  return (
    <section className='contact section-p-top bg-black' id = "contact" >
        <div className='container contact_container'>
            <div className='contact-content grid text-center'>
              
                    <div className='section-t'>
                        <h3 >{t("contact.heading")} </h3> 
                        <p className='text'>{t("contact.leadingText")}</p>
                        <div className='socialMediaIconsContainer'>
                           <a href="https://www.facebook.com/profile.php?id=61556602853654"> <FaFacebook/></a>
                            <a href="https://www.instagram.com/simple_codehub/"><BsInstagram  /></a>
                        </div>
                    </div>

                    <Formik
                        initialValues={{ name: "", email: '', address: '' }}
                        validate={values => {
                            const errors = {};

                            if(!values.name){
                                errors.name = "Name is required";
                            } else if(!/^[A-Za-z\s]*$/.test(values.name)){
                                errors.name = "Invalid name format";
                            }

                            if (!values.email) {
                                errors.email = 'Email is required';
                            } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalid email address';
                            }

                            if(!values.address){
                                errors.address = "Address is required";
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                            alert(JSON.stringify(values, null, 2));
                            setSubmitting(false);
                            }, 400);
                        }}
                        >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className='form-elem'>
                                    <input type = "text" name = "name" onChange = {handleChange} placeholder={t("contact.fullname")} onBlur = {handleBlur} value = {values.name} className = "form-control" />
                                    <span className='form-control-text'>{errors.name && touched.name && errors.name}</span>
                                </div>

                                <div className='form-elem'>
                                    <input type = "email" name = "email" placeholder={t("contact.email")} onChange = {handleChange} onBlur = {handleBlur} value = {values.email} className = "form-control" />
                                    <span className='form-control-text'>{errors.email && touched.email && errors.email}</span>
                                </div>

                                <div className='form-elem'>
                                    <input type = "text" name = "address" placeholder={t("contact.address")} onChange = {handleChange} onBlur = {handleBlur} value = {values.address} className = "form-control" />
                                    <span className='form-control-text'>{errors.address && touched.address && errors.address}</span>
                                </div>

                                <div className='form-elem'>
                                    <input type = "text" name = "phoneNumber" placeholder={t("contact.phonenumber")} onChange = {handleChange} onBlur = {handleBlur} value = {values.address} className = "form-control" />
                                    <span className='form-control-text'>{errors.address && touched.address && errors.address}</span>
                                </div>

                                <div className='flex flex-start'>
                                    <button type = "submit" disabled = {isSubmitting} className = "submit-btn"><BiPaperPlane size={20}/> {t("contact.sendBtnText")}</button>
                                </div>
                            </form>
                        )}
                    </Formik>
              
            </div>
        </div>

        <Info />
        <Map />

    </section>
  )
}

export default Contact
