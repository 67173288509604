import React from 'react'
import Slider from './Slider'

function Products() {
  return (
    <div id="products" >
      
      <Slider/></div>
  )
}

export default Products