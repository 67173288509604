import React from 'react';
import "./Services.css";

import SingleService from './SingleService';
import { useTranslation } from 'react-i18next';
import { BiSupport } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import { MdDeveloperMode, MdOutlineDeveloperBoard, MdOutlineWeb } from "react-icons/md";
import {ImMagicWand} from "react-icons/im";


const Services = () => {

    const {t} = useTranslation();
    
    const services = [
        {
            id: 1,
            icon: <MdOutlineDeveloperBoard  />,
            title: t("services.cards.card1.heading"),
            text: t("services.cards.card1.text")
        },
        {
            id: 2,
            icon: <MdDeveloperMode   />,
            title: t("services.cards.card2.heading"),
    
            text: t("services.cards.card2.text")
        },
        {
            id: 3,
            icon: <CgWebsite   />,
            title: t("services.cards.card3.heading"),
            text: t("services.cards.card3.text")
        },
        {
            id: 4, 
            icon: <MdOutlineWeb   />,
            title: t("services.cards.card4.heading"),
            text: t("services.cards.card4.text")
        },
        {
            id: 5,
            icon: <ImMagicWand   />,
            title: t("services.cards.card5.heading"),
            text: t("services.cards.card5.text")
        },
        {
            id: 6,
            icon: <BiSupport />,
            title: t("services.cards.card6.heading"),
            text: t("services.cards.card6.text")
        }
    ];
  return (
    <section className='services section-p bg-md-black' id = "services">
         <h5 className="aboutUs-heading">{t("services.heading")}</h5>
        <div className='container'>
            <div className='services-content'>
                <svg width = "1em" height = "1em">
                    <linearGradient id = "blue-gradient" x1 = "100%" y1 = "100%" x2 = "0%" y2 = "0%">
                        <stop stopColor = "#55b3d5" offset="0%" />
                        <stop stopColor = "#5764de" offset = "100%" />
                    </linearGradient>
                </svg>

                <div className='item-list grid text-white text-center'>
                    {
                        services.map(service => {
                            return (
                                <SingleService service = {service} key = {service.id} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </section>
  )
}

export default Services