import './App.css';
import Header from './components/Header/Header';
import "./assets/js/script";
import Services from './components/Services/Services';
import About from './components/About/About';
import Features from './components/Features/Features';
import Contact from './components/Contact/Contact';
import Products from './components/products/products';
import  './languages/i18n';
import LanguageSelector from './constants/LanguageSelector';
import HeroSection from './components/HeroSection/herosection';
function App() {
  return (
    <div className="App">
      <HeroSection/>
      <Header />
      <About />
      <Services />
      <Features />
      <Products/>
      <Contact  />
     <LanguageSelector/>

      
     
    </div>
  );
}

export default App;
